<template>
  <div v-if="camera">
    <table class="defaultTable border-top-0">
      <thead>
        <tr>
          <th>{{ $t('previewImage') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <img
              v-if="sidebarImage"
              :src="`data:image/png;base64, ${ sidebarImage.base64Data }`"
              :alt="sidebarImage.fileName"
            >
            <LoadingPlaceholder v-else />
          </td>
        </tr>
      </tbody>
    </table>

    <table class="defaultTable">
      <colgroup>
        <col width="150">
      </colgroup>
      <tbody>
        <tr>
          <th>{{ $t('cameraManagementComponent.lastActive') }}</th>
          <td v-tooltip="dateTime_fromNow(camera.lastActiveDate)">
            {{ camera.lastActiveDate | dateTime_dateTime }}
          </td>
        </tr>
        <tr>
          <th>{{ $t('ipAddress') }}</th>
          <td>{{ camera.ipAddress }}</td>
        </tr>
        <tr>
          <th>{{ $t('cameraManagementComponent.manufacturer') }}</th>
          <td>{{ camera.manufacturer }}</td>
        </tr>
        <tr>
          <th>{{ $t('cameraManagementComponent.model') }}</th>
          <td>{{ camera.model }}</td>
        </tr>
        <tr>
          <th>{{ $t('cameraManagementComponent.lense') }}</th>
          <td>{{ camera.lensCategory }}</td>
        </tr>
        <tr>
          <th>{{ $t('cameraManagementComponent.maxFocalLength') }}</th>
          <td>{{ camera.maximumFocalLength }}</td>
        </tr>
        <tr>
          <th>{{ $t('cameraManagementComponent.minFocalLength') }}</th>
          <td>{{ camera.minimumFocalLength }}</td>
        </tr>
        <tr>
          <th>{{ $t('cameraManagementComponent.sensorWidth') }}</th>
          <td>{{ camera.sensorWidth }}</td>
        </tr>
        <tr>
          <th>{{ $t('cameraManagementComponent.sensorHeight') }}</th>
          <td>{{ camera.sensorHeight }}</td>
        </tr>
        <tr>
          <th>{{ $t('cameraManagementComponent.motionDetectionStream') }}</th>
          <td>{{ camera.motionDetectionStreamUrl }}</td>
        </tr>
        <tr>
          <th>{{ $t('cameraManagementComponent.recordingStream') }}</th>
          <td>{{ camera.recordingStreamUrl }}</td>
        </tr>
        <tr>
          <th>{{ $t('cameraManagementComponent.panTiltConfig') }}</th>
          <td>
            <pre>{{ camera.panTiltConfig }}</pre>
          </td>
        </tr>
      </tbody>
    </table>
   
    <LoadingPlaceholder
      v-if="loadingZoom"
      class="mt-4"
    />
    <table
      v-else-if="zoomPercent != null"
      class="defaultTable border-top-0"
    >
      <thead>
        <tr>
          <th>{{ $t('cameraManagementComponent.currentZoom') }}</th>
          <th>{{ $t('zoom') }}</th>
          <th>{{ $t('cameraManagementComponent.smooth') }}</th>
          <th>{{ $t('options') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ zoomPercent }}%</td>
          <td>
            <div class="input-group input-group-sm">
              <input
                v-model="zoomToSet"
                type="number"
                max="100"
                min="0"
                class="form-control form-control-sm"
              >
              <div class="input-group-append">
                <span class="input-group-text">%</span>
              </div>
            </div>
          </td>
          <td>
            <toggle-button
              v-model="smooth"
              :labels="{ checked: $t('cameraManagementComponent.smooth'), unchecked: $t('cameraManagementComponent.notSmooth') }"
              :width="110"
              :height="30"
              :font-size="12"
              class="m-0"
            />
          </td>
          <td>
            <div class="btn-group btn-group-sm">
              <button
                class="btn btn-primary btn-sm"
                @click="setZoom()"
              >
                <font-awesome-icon
                  class="mr-1"
                  icon="edit"
                  style="vertical-align: middle;"
                />
                {{ $t('cameraManagementComponent.set') }}
              </button>
              <button
                class="btn btn-danger btn-sm"
                @click="stopZoom()"
              >
                <font-awesome-icon
                  class="mr-1"
                  icon="stop"
                  style="vertical-align: middle;"
                />
                {{ $t('cameraManagementComponent.stop') }}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <template v-if="hasIpAddress">
      <LoadingPlaceholder
        v-if="loadingPositions"
        class="mt-4"
      />
      <table
        v-else-if="positions != null"
        class="defaultTable border-top-0"
      >
        <thead>
          <tr>
            <th>{{ $t('pan') }} ({{ positions ? positions[cameraId] ? positions[cameraId].pan : `?` : `?` }})</th>
            <th>{{ $t('tilt') }} ({{ positions ? positions[cameraId] ? positions[cameraId].tilt : `?` : `?` }})</th>
            <th>{{ $t('options') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="input-group input-group-sm">
                <input
                  v-model="panToSet"
                  type="number"
                  max="180"
                  min="-180"
                  class="form-control form-control-sm"
                >
                <div class="input-group-append">
                  <span class="input-group-text">°</span>
                </div>
              </div>
            </td>
            <td>
              <div class="input-group input-group-sm">
                <input
                  v-model="tiltToSet"
                  type="number"
                  max="180"
                  min="-180"
                  class="form-control form-control-sm"
                >
                <div class="input-group-append">
                  <span class="input-group-text">°</span>
                </div>
              </div>
            </td>
            <td>
              <button
                class="btn btn-primary btn-sm btn-block"
                @click="moveTo()"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="arrows-alt"
                  style="vertical-align: middle;"
                />
                {{ $t('cameraManagementComponent.move') }}
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <div class="w-50 float-left pr-1">
                <button
                  class="btn btn-primary btn-sm btn-block"
                  @click="moveToDefaultPosition()"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="map-marker-alt"
                    style="vertical-align: middle;"
                  />
                  {{ $t('cameraManagementComponent.moveToDefaultPosition') }}
                </button>
              </div>
              <div class="w-50 float-left pl-1">
                <button
                  class="btn btn-primary btn-sm btn-block"
                  @click="moveToProtectionPosition()"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="shield-alt"
                    style="vertical-align: middle;"
                  />
                  {{ $t('cameraManagementComponent.moveToProtectionPosition') }}
                </button>
              </div>
              <div class="clearfix" />
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <button
      v-if="hasIpAddress && isVideoDirector && recorderId"
      v-tooltip="$t('videoDirectorDetailsComp.reinitializePtHeadTooltip')"
      class="btn btn-primary float-right btn-sm mt-3 mb-3"
      @click="reinitializePtHead()"
    >
      <font-awesome-icon
        class="mr-1"
        icon="sync-alt"
        style="vertical-align: middle;"
      />
      {{ $t('videoDirectorDetailsComp.reinitializePtHead') }}
    </button>
  </div>
</template>
<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: 'CameraManagement',
  mixins: [
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    cameraId: {
      type: Number,
      required: true
    },
    recorderId: {
      type: Number,
      required: false,
      default () {
        return null;
      }
    },
    isVideoDirector: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      panToSet: 0,
      tiltToSet: 0,
      smooth: true,
      camera: null,
      sidebarImage: null,
      zoomPercent: null,
      zoomToSet: 0,
      loadingZoom: true,
      loadingPositions: true,
      positions: null,
      cancelImageSource: null,
      cancelZoomSource: null,
      cancelStopZoomSource: null,
      cancelDefaultPositionSource: null,
      cancelProtectedPositionSource: null,
      cancelMoveSource: null,
      cancelPositionSource: null,
      cancelSetZoomSource: null,
      imageInterval: null
    }
  },
  computed: {
    hasIpAddress () {
      if (this.camera == null) {
        return false;
      }
      if (this.camera.panTiltConfig == null) {
        return false;
      }
      if (this.camera.panTiltConfig.ipAddress == null) {
        return false;
      }
      if (this.camera.panTiltConfig.ipAddress.length == 0) {
        return false;
      }
      return true;
    }
  },
  async created () {
    await this.getCamera();
    this.createImageInterval();
  },
  beforeDestroy () {
    this.clearImageInterval();
    this.cancelImageRequest();
  },
  methods: {
     cancelImageRequest () {
      if (this.cancelImageSource) {
        this.cancelImageSource.cancel();
      }
    },
    cancelPositionRequest () {
      if (this.cancelPositionSource) {
        this.cancelPositionSource.cancel();
      }
    },
    cancelDefaultPositionRequest () {
      if (this.cancelDefaultPositionSource) {
        this.cancelDefaultPositionSource.cancel();
      }
    },
    cancelProtectedPositionRequest () {
      if (this.cancelProtectedPositionSource) {
        this.cancelProtectedPositionSource.cancel();
      }
    },
    cancelGetZoomRequest () {
      if (this.cancelZoomSource) {
        this.cancelZoomSource.cancel();
      }
    },
    cancelStopZoomRequest () {
      if (this.cancelStopZoomSource) {
        this.cancelStopZoomSource.cancel();
      }
    },
    cancelSetZoomRequest () {
      if (this.cancelSetZoomSource) {
        this.cancelSetZoomSource.cancel();
      }
    },
    cancelMoveRequest () {
      if (this.cancelMoveSource) {
        this.cancelMoveSource.cancel();
      }
    },
    clearImageInterval () {
      window.clearInterval(this.imageInterval);
      this.imageInterval = null;
    },
    async getCamera () {
      let url = `/CameraManagement/GetCamera?installationId=${ this.installationId }&cameraId=${ this.cameraId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      await this.axios.get(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          this.camera = response.data;
        });
    },
    createImageInterval () {
      this.imageInterval = window.setInterval(() => {
        this.getCameraImages();
        if (this.camera.fullCameraControl)
        {
          this.getZoom();
          this.getPositions();
        }
        else
        {
          this.loadingZoom = false;
          this.loadingPositions = false;
        }    

      }, 1000);
    },
    getCameraImages () {
      this.cancelImageRequest();
      this.cancelImageSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelImageSource.token };
      let url = `/CameraManagement/GetImage?installationId=${ this.installationId }&cameraId=${ this.cameraId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status != 200) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.data == undefined) {
            return;
          }
          if (this.camera == null) {
            return;
          }
          this.sidebarImage = response.data;
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        });
    },
    getZoom () {
      if (this.cameraId == null || !this.camera.lensCategory || this.camera.lensCategory.toLowerCase() === 'primelens') {
        return;
      }
      this.cancelGetZoomRequest();
      this.cancelZoomSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelZoomSource.token };
      let url = `/CameraManagement/GetZoom?installationId=${ this.installationId }&cameraId=${ this.cameraId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status != 200) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.data.zoom == null) {
            return;
          }
          this.zoomPercent = response.data.zoom;
          if (this.zoomToSet == null) {
            this.zoomToSet = response.data.zoom;
          }
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        })
        .finally(() => {
          this.loadingZoom = false;
        });
    },
    getPositions () {
      this.loading = true;
      this.cancelPositionRequest();
      this.cancelPositionSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelPositionSource.token };
      let url = `/CameraManagement/GetPositions?installationId=${ this.installationId }&cameraIds=${ this.cameraId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.positions = response.data;
        })
        .finally(() => {
          this.loadingPositions = false;
        });
    },
    moveTo () {
      this.cancelMoveRequest();
      this.cancelMoveSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelMoveSource.token };
      let url = `/CameraManagement/Move?installationId=${ this.installationId }&cameraIds=${ this.cameraId }&pan=${ this.panToSet }&tilt=${ this.tiltToSet }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status == 200) {
            this.$snotify.success(this.$t('cameraManagementComponent.cameraMovedSuccessfully'));
          }
        });
    },
    setZoom () {
      this.cancelSetZoomRequest();
      this.cancelSetZoomSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelSetZoomSource.token };
      let url = `/CameraManagement/SetZoom?installationId=${ this.installationId }&cameraId=${ this.cameraId }&percentage=${ Number(this.zoomToSet) > 100 ? 100 : Number(this.zoomToSet) < 0 ? 0 : Number(this.zoomToSet) }&smooth=${ this.smooth }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status != 200) {
            return;
          }
          if (response.data == null) {
            return;
          }
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        })
        .finally(() => {
          this.getZoom();
        });
    },
    stopZoom () {
      this.cancelStopZoomRequest();
      this.cancelStopZoomSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelStopZoomSource.token };
      let url = `/CameraManagement/StopZoom?installationId=${ this.installationId }&cameraId=${ this.cameraId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.delete(url, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status != 200) {
            return;
          }
          if (response.data == null) {
            return;
          }
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        })
        .finally(() => {
          this.getZoom();
        });
    },
    moveToDefaultPosition () {
      this.loading = true;
      this.cancelDefaultPositionRequest();
      this.cancelDefaultPositionSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelDefaultPositionSource.token };
      let url = `/CameraManagement/MoveToDefaultPosition?installationId=${ this.installationId }&cameraIds=${ this.cameraId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status == 200) {
            this.$snotify.success(this.$t('cameraManagementComponent.cameraMovedSuccessfully'));
          }
        });
    },
    moveToProtectionPosition () {
      this.loading = true;
      this.cancelProtectedPositionRequest();
      this.cancelProtectedPositionSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelProtectedPositionSource.token };
      let url = `/CameraManagement/MoveToProtectionPosition?installationId=${ this.installationId }&cameraIds=${ this.cameraId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status == 200) {
            this.$snotify.success(this.$t('cameraManagementComponent.cameraMovedSuccessfully'));
          }
        });
    },
    reinitializePtHead () {
      let url = `/VideoDirector/ReinitializePtHead?installationId=${ this.installationId }&recorderId=${ this.recorderId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status == 200) {
            this.$snotify.success(this.$t('videoDirectorDetailsComp.reinitializePtHeadSuccess'));
          }
        })
        .catch((error) => {
          this.$snotify.error(this.$t('videoDirectorDetailsComp.reinitializePtHeadError'));
          this.error_clear();
          this.error_validate(error);
        });
    },
  }
}
</script>

<style scoped>
.sidebar img {
  width: 100%;
  height: auto;
}
</style>

<style>
.cameraManagement .m-portlet__body {
  min-height: 105px !important;
}
</style>
